import React from 'react';
import './styles/Terms.css'; // Ensure you have this CSS file for styling

const Terms = () => {
    return (
        <div className="terms-container">
            <h1>Welcome to Your Terms and Conditions</h1>
            
            <section className="terms-section">
                <h2>Acceptance of Terms</h2>
                <p>Thank you for choosing Nzubo! We kindly ask you to read our Terms of Use carefully.</p>
            </section>

            <section className="terms-section">
                <h2>Sign-Up Legal Compliance</h2>
                <p>To get started, please provide a Proof of Residence and your Phone Number on Nzubo. This helps us keep our community safe and connected.</p>
            </section>

            <section className="terms-section">
                <h2>Accommodation Listings</h2>
                <p>
                    By listing your accommodation on Nzubo, you're joining a vibrant community. These terms create a friendly partnership between you (the "Provider" or "Business") and Nzubo (the "Company").
                </p>
            </section>

            <section className="terms-section">
                <h2>Booking Confirmation</h2>
                <p>
                    Once you receive a booking request through Nzubo, please confirm or decline it as soon as possible. We understand life can be busy, but timely responses help everyone. If we don’t hear back from you within the specified timeframe, the booking request may be automatically canceled.
                </p>
            </section>

            <section className="terms-section">
                <h2>Accommodation Availability</h2>
                <p>
                    Keeping your availability calendar up-to-date is super important! If your accommodation becomes unavailable due to unforeseen circumstances, we ask that you let both the affected guest and Nzubo know right away so we can find a solution together.
                </p>
            </section>

            <section className="terms-section">
                <h2>Property Condition and Compliance</h2>
                <p>
                    It's important that the accommodations you offer meet all necessary legal and safety standards. Please ensure your properties are well-maintained and compliant with local laws and regulations.
                </p>
            </section>

            <section className="terms-section">
                <h2>Customer Service</h2>
                <p>
                    Providing excellent customer service is key! As a Provider, we encourage you to respond promptly to any guest inquiries or concerns. Nzubo is here to support you with guidelines and tips to help you shine in customer service.
                </p>
            </section>

            <section className="terms-section">
                <h2>Reviews and Ratings</h2>
                <p>
                    Our guests will have the chance to leave reviews and ratings based on their experiences with your accommodations. We display these reviews on our platform, and they can help build your reputation and future bookings. Embrace the feedback—it’s all part of the journey!
                </p>
            </section>

            <section className="terms-section">
                <h2>Termination</h2>
                <p>
                    We want to maintain a friendly and trustworthy environment. Nzubo reserves the right to terminate your listing and access to our platform if there’s any non-compliance with these terms, fraudulent activities, misconduct, or any violations. We believe in fair play for everyone.
                </p>
            </section>

            <section className="terms-section">
                <h2>Modifications</h2>
                <p>
                    From time to time, we may update these terms and conditions to keep things fresh and clear. We’ll communicate any changes via email or through the platform, and by continuing to use Nzubo after receiving a notification, you’re accepting the updated terms. We appreciate your understanding!
                </p>
            </section>
        </div>
    );
}

export default Terms;
