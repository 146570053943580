import React, {useState} from 'react';
import './styles/Contact.css'; // Make sure the path is correct

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    
      const [isSent, setIsSent] = useState(false);
      const [error, setError] = useState('');
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('http://localhost:3004/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            setIsSent(true);
            setFormData({ name: '', email: '', subject: '', message: '' });
          } else {
            setError('Failed to send message. Please try again later.');
          }
        } catch (err) {
          setError('Failed to send message. Please try again later.');
          console.error(err);
        }
      };
    
      return (
        <div className='contact-container'>
          {isSent ? (
            <p>Thank you! Your message has been sent.</p>
          ) : (
            <form id="contact-form" onSubmit={handleSubmit}>
              <div className="row">
                <h3>Send us an email</h3>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="name">Full Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name..."
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your E-mail..."
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject..."
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <button type="submit" id="form-submit" className="orange-button">
                      Send Message
                    </button>
                  </fieldset>
                </div>
              </div>
            </form>
          )}
    
          {error && <p className="error-message">{error}</p>}
        </div>
      );
    };

export default Contact;
