import React from 'react';

const Services = () => {
  return (
    <div>
      <h1>Our Services</h1>
      <p>Explore our various services tailored to your needs.</p>
    </div>
  );
};

export default Services;
