import React, { useState } from 'react';
import './Navbar.css'; // Import the CSS file for the navbar
import Logo from '../assets/images/logo.png';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        {/* Link the logo to the home page */}
        <NavLink to="/" className="logo-link">
          <img src={Logo} alt="Logo" className="logo" />
          <span className="brand-name">Nzubo</span>
        </NavLink>
      </div>

      {/* Add 'active' class when the menu is toggled */}
      <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li><NavLink to="/" activeClassName="active">Home</NavLink></li>
        <li><NavLink to="/about" activeClassName="active">About Us</NavLink></li>
        <li><NavLink to="/services" activeClassName="active">Services</NavLink></li>
        <li><NavLink to="/contact" activeClassName="active">Contact</NavLink></li>
        <li><NavLink to="/terms" activeClassName="active">Terms & Conditions</NavLink></li>
      </ul>

      {/* Hamburger menu for mobile view */}
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        {/* Change the icon based on the isOpen state */}
        {isOpen ? (
          <span className="hamburger-close">✖</span> // 'X' icon
        ) : (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
