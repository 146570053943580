import React, { useState, useRef } from 'react';
import axios from 'axios';
import logo from '../logo.svg';
import './styles/Home.css';
import './styles/Results.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faMicrochip, faMapMarkerAlt, faChevronUp } from '@fortawesome/free-solid-svg-icons'; // Importing Chevron icon for the button

const Spinner = () => (
    <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
    </div>
);

function Home() {
    const [formData, setFormData] = useState({
        location: '',
        checkin: '',
        checkout: '',
        guests: 0,
    });

    const [result, setResult] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const searchResultsRef = useRef(null); // Create a ref for search results

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult([]);
        setLoading(true);

        try {
            const response = await axios.post('/api/search', formData);
            if (response.data.error) {
                setError(response.data.error);
            } else {
                const resultsArray = response.data.result.split('\n').filter((line) => line.trim() !== '');
                setResult(resultsArray);
            }
        } catch (err) {
            setError('Something went wrong. Please try again later.');
            console.log(err);
        } finally {
            setLoading(false);
            // Scroll to the search results after loading
            searchResultsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleFindNearMe = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    try {
                        // Google Maps Geocoding API to convert coordinates to address
                        const response = await fetch(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBLYlw_XNJW_X7vpDZx4hjAh0YRbI48yuQ`
                        );
                        const data = await response.json();

                        if (data.status === 'OK' && data.results.length > 0) {
                            const addressComponents = data.results[0].address_components;

                            // Extract desired components (neighborhood, city, country)
                            let neighborhood = '';
                            let city = '';
                            let country = '';

                            addressComponents.forEach((component) => {
                                if (component.types.includes('sublocality') || component.types.includes('neighborhood')) {
                                    neighborhood = component.long_name;
                                }
                                if (component.types.includes('locality')) {
                                    city = component.long_name;
                                }
                                if (component.types.includes('country')) {
                                    country = component.long_name;
                                }
                            });

                            const location = `${city}, ${country}`;
                            setFormData({
                                ...formData,
                                location: location,
                            });
                        } else {
                            setError('Unable to retrieve location details.');
                        }
                    } catch (error) {
                        console.error('Error with Google Geocoding API: ', error);
                        setError('Unable to retrieve location details.');
                    }
                },
                (error) => {
                    console.error('Error getting location: ', error);
                    setError('Unable to retrieve your location.');
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0,
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    // Scroll to the top of the form
    const scrollToTop = () => {
        const formElement = document.getElementById('searchContainer');
        formElement.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="App">
            <div className="search-container" id="searchContainer">
                <form id="searchForm" className="search-form" onSubmit={handleSubmit}>
                    <h2>Find local stays near you</h2>

                    <div className="form-group">
                        <label htmlFor="location">
                            <FontAwesomeIcon icon={faMap} /> Location
                        </label>
                        <div className="input-icon-container">
                            <FontAwesomeIcon icon={faMicrochip} className="ai-icon" />
                            <input
                                type="text"
                                id="location"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                                placeholder="Where do you want to stay"
                                required
                            />
                        </div>
                        <button type="button" className="btn-near-me" onClick={handleFindNearMe}>
                            {loading ? <Spinner /> : <>Find Near Me <FontAwesomeIcon icon={faMapMarkerAlt} /></>}
                        </button>
                    </div>

                    <button type="submit" className="btn-submit">Search</button>
                </form>
            </div>

            <div ref={searchResultsRef} id="searchResults" className='search-results'>
                {loading && <Spinner className="loading-spinner" />}
                {error && <p className="error">{error}</p>}
                {result.length > 0 && (
                    <ul className="results-list">
                        <li><strong>Stay in one of these places:</strong></li>
                        {result.map((item, index) => (
                            <li key={index}>
                                {/* <strong>{index + 1}.</strong> */} {item}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
{/* 
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Edit <code>src/App.js</code> and save to reload.</p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header> */}

            {/* Floating Button for scrolling */}
            <button className="floating-button" onClick={scrollToTop} aria-label="Scroll to top">
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
        </div>
    );
}

export default Home;
