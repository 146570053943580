import React from 'react';
import './styles/About.css'; // You can create a CSS file for styling if needed

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>Welcome to Nzubo! We are dedicated to helping you find the best accommodation that fits your needs.</p>
      
      <h2>Our Mission</h2>
      <p>
        At Nzubo, our mission is simple: we aim to make it easy for people to find accommodation nearby, 
        all while delivering the best service and ensuring your safety. Your comfort and satisfaction 
        are our top priorities.
      </p>
      
      <h2>Our Leadership Strategy</h2>
      <p>
        We strive to be a company known for its professionalism, strong values, and commitment to 
        simplicity. Our leadership approach focuses on making it as straightforward as possible for 
        our customers to find their ideal accommodation.
      </p>
      
      <h2>Join Us on Our Journey</h2>
      <p>
        Thank you for choosing Nzubo! We look forward to being part of your journey and helping you find 
        your perfect stay.
      </p>
    </div>
  );
};

export default About;
